import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../style-index.css";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>Admin Tools</h1>
    <p>
  <span>Administrators</span>
    </p>
    <h2 {...{
      "id": "custom-domain"
    }}>{`Custom Domain`}</h2>
    <p>{`White-Label feature is available for customers with a paid white-label subscription.
Team Coaches allows you to point your on subdomain to Team Coaches and being able to have all logos custom as per your requirements.`}</p>
    <p><strong parentName="p">{`Step 1:`}</strong>{` Login to your DNS registrar and go the DNS configuration.
In the configuration add an entry for CNAME as follows:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`subdomain:`}</strong>{` platform`}</li>
      <li parentName="ul"><strong parentName="li">{`Point to:`}</strong>{` caddy.teamcoach.es`}</li>
    </ul>
    <p>{`Note: platform is an example. You can chose any subdomain of your preference. There is a number of YouTube tutorial that show how to configure CNAME.`}</p>
    <p>{`Check that your CNAME configuration is working by visiting this link: `}<a parentName="p" {...{
        "href": "https://dnschecker.org/#CNAME"
      }}>{`https://dnschecker.org/#CNAME`}</a></p>
    <p>{`When defining your subdomain, make sure you exclude “www.” from it. For example, “`}<a parentName="p" {...{
        "href": "https://www.platform.website.com%E2%80%9D"
      }}>{`https://www.platform.website.com”`}</a>{` is wrong, correct value in this case is “`}<a parentName="p" {...{
        "href": "https://platform.website.com%E2%80%9D"
      }}>{`https://platform.website.com”`}</a></p>
    <p><strong parentName="p">{`Step 2:`}</strong>{` Wait for the DNS changes to propagate and use dnscheck.org to verify it.
Make sure that dnscheck.org shows that your subdomain is pointing to caddy.teamcoach.es before proceeding.`}</p>
    <p><strong parentName="p">{`Step 3:`}</strong>{` Notify Team Coaches support what is the subdomain chosen via email to support@teamcoach.es, before 24hrs the changes will take place.`}</p>
    <p>{`Instruction for squarespace can be found here: `}<a parentName="p" {...{
        "href": "https://support.squarespace.com/hc/en-us/articles/206542017-Connecting-a-subdomain-to-your-Squarespace-site"
      }}>{`https://support.squarespace.com/hc/en-us/articles/206542017-Connecting-a-subdomain-to-your-Squarespace-site`}</a></p>
    <h2 {...{
      "id": "vimeo-configuration-for-private-videos"
    }}>{`Vimeo Configuration for private videos`}</h2>
    <p>{`First of all to use this option you must have a paid account. A “Plus” account is enough if you are planning to upload some few video. You can check Vimeo pricing `}<a parentName="p" {...{
        "href": "https://vimeo.com/upgrade"
      }}>{`https://vimeo.com/upgrade`}</a></p>
    <p>{`Now let us see what are the options that we need to change to make them private.`}</p>
    <p>
  Go to Vimeo account and click in <span>Upload Video</span>. Once here click in
  Public and you will have Privacy options.
    </p>
    <p>{`The following options will depend on what you want to do:
`}<strong parentName="p">{`Allow downloads:`}</strong>{` this will allow people with low connection or no internet connection to download your video to watch it offline.
`}<strong parentName="p">{`Link:`}</strong>{` In this section you have the option on how you want to share the video. The options that are relevant for Team Coaches are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hide from Vimeo:`}</strong>{` this allows you to embed the video into Team Coaches but block the video to be watched on the Vimeo website.`}</li>
      <li parentName="ul"><strong parentName="li">{`Public:`}</strong>{` this allows everyone to watch the video. This option is relevant if you don’t care about sharing your video online.`}</li>
      <li parentName="ul"><strong parentName="li">{`Embed:`}</strong>{` if you choose “Hide from Vimeo” then you have the option to give more privacy to your video. In this option you can add teamcoach.es as preferred domain so that even with the video link the same can not be uploaded and watched somewhere else.`}</li>
    </ul>
    <p>
  Now you just need to <span>Drag and Drop to upload</span> or{" "}
  <span>Choose a file</span> and your video will be uploaded.
    </p>
    <h2 {...{
      "id": "advanced-options"
    }}>{`Advanced Options`}</h2>
    <p>
  In your main page where all the videos are you can click on the 3 dots and
  chose <span>Embed</span>.
    </p>
    <p>{`Additional options can be configured here, we will cover what is relevant for Team Coaches customers.`}</p>
    <p>
  <strong>General</strong> has the Info, Privacy and Collections sections. In
  Privacy section in addition of what has been described in our initial section
  you have <span>Who can comment</span>. In this case is recommended to chose{" "}
  <span>No one</span> to block interaction with the video.
    </p>
    <p><strong parentName="p">{`Embed`}</strong>{` has Web and Email section. In the Web section we have option that we want to remove to make the video more personalized.`}</p>
    <p>{`In Controls is recommended to leave all enabled except for Speed controls like Playbar, Volume, and Fullscreen.`}</p>
    <p>{`In Actions is recommended to disable all the options, like Like, Watch Later, Share and Embed.
In Your details is recommended to disable Let users decide, and then disable Profile picture, Title, and Byline.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      